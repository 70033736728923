<template>
  <div>
    <Form inline>
      <FormItem>
        <Input v-model="queryForm.keyword" type="text" placeholder="搜索" />
      </FormItem>
      <!-- <FormItem>
        <FormItem>
          <Select v-model="queryForm.district" placeholder="区域过滤">
            <Option :value="''">不限</Option>
            <Option :value="'Auckland City'">中区</Option>
            <Option :value="'Manukau City'">东南区</Option>
            <Option :value="'North Shore City'">北岸</Option>
            <Option :value="'Papakura'">南区</Option>
            <Option :value="'Waitakere City'">西区</Option>
            <Option :value="'Franklin'">西南区</Option>
            <Option :value="'Rodney'">西北区</Option>
          </Select>
        </FormItem>
      </FormItem> -->
      <FormItem>
        <Select v-model="queryForm.businessTypeAlias" placeholder="业态过滤">
          <Option v-for="item in businessTypeInList" :value="item.alias" :key="item.alias">
            {{ item.name }}
          </Option>
        </Select>
      </FormItem>
    </Form>
    <Table stripe :columns="columns" :data="itemList" :loading="tableLoading">
    </Table>
    <Pager :total="itemListCount" :current.sync="page" />
  </div>
</template>
  
<script>
import Pager from "@/components/Pager.vue"
import { queryStore, queryBusinesstype } from "@/api"
import { toHumanDate, apiUtil } from "@/util"
import "@/components/tableUtil"

export default {
  name: "StoreList",
  components: { Pager },
  data() {
    return {
      createdAtRange: ["", ""],
      queryForm: {
        keyword: "",
        district: null,
        businessTypeAlias: null,
        startTime: null,
        endTime: null,
      },
      page: 1,
      tableLoading: false,
      itemList: [],
      itemListCount: 0,
      showItemDetail: false,
      currentItem: "",
      columns: [
        { title: "标识", key: "storeAlias", width: 100, maxWidth: 200, fixed: "left" },
        {
          title: "名称",
          maxWidth: 200,
          minWidth: 100,
          render: (h, params) => {
            return h(
              "a",
              {
                on: {
                  click: () => {
                    this.$emit("selectionChanged", params.row)
                  },
                },
              },
              [params.row.name],
            )
          },
          fixed: "left",
        },
        {
          title: "店长",
          render(h, p) {
            return h("div", p.row.seller.nickname)
          },
          width: 150,
          fixed: "left",
        },
        {
          title: "区域",
          width: 350,
          render(h, params) {
            return h("div", params.row.address.fullAddress)
          },
        },
        {
          title: "创建时间",
          key: "createdAt",
          render: (h, p) => h("div", toHumanDate(p.row.createdAt)),
          width: 100,
        },
        {
          title: "状态",
          render: (h, params) => {
            return h("div", params.row.status ? "正常" : "禁用")
          }
        }
      ],
      isShowEdit: false,
      businessTypeInList: []
    }
  },
  computed: {},
  watch: {
    async page() {
      await this.reload()
    },
    async createdAtRange() {
      await this.reload()
    },
    queryForm: {
      deep: true,
      async handler() {
        await this.reload()
      },
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    process(cate) {
      if (cate.children && !cate.isLeaf) {
        cate.children = cate.children.map(item => this.process(item))
      }
      cate.expand = true
      cate.title = cate.name
      this.businessTypeInList.push({ name: cate.name, alias: cate.alias })
    },
    async reload() {
      try {
        this.tableLoading = true
        const q = this.queryForm
        let startTime = null,
          endTime = null
        if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
          startTime = this.createdAtRange[0].getTime()
          endTime = this.createdAtRange[1].getTime()
        }
        let status = null
        if (q.status > -1) {
          status = q.status
        }
        let type = null
        if (q.type > -1) {
          type = q.type
        }
        const { items, count } = await queryStore({
          page: this.page,
          pageSize: 20,
          keyword: q.keyword,
          businessTypeAlias: q.businessTypeAlias,
          startTime: startTime,
          district: q.district,
          endTime: endTime
        })
        this.itemList = items
        this.itemListCount = count
        this.tableLoading = false
        this.process(await queryBusinesstype())
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    showItem(row) {
      this.showItemDetail = true
      this.currentItem = row.itemAlias
    },
    async onNewSubmit() {
      apiUtil.call(
        this,
        async () => {
          const editor = this.$refs.editor
          const formData = Object.assign({}, editor.form)
          console.log(formData)
        },
        () => {
          this.dialogDisplay = true
        },
      )
    }
  },
}
</script>
  