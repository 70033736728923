<template>
  <div>
    <Select style="width:200px" v-model="modelValue">
      <Option v-for="item in eventList" :value="item.key" :key="item.key">{{ item.name }}</Option>
    </Select>
    <Button @click="haddleClickEnter">确定</Button>
  </div>
</template>

<script>
export default {
  name: "EventList",
  components: {},
  data() {
    return {
      modelValue: null,
      eventList: [
        { name: "团购白名单", key: "GROUPBUY_WHITELIST" },
        { name: "到店白名单", key: "STORE_WHITELIST" },
        { name: "美食餐厅", key: "FOOD_RESTAURANT" },
        { name: "套餐优惠", key: "PACKAGE_DISCOUNT" },
        { name: "1刀秒杀", key: "SECKILL" }
      ]
    }
  },
  methods: {
    haddleClickEnter() {
      const eventItem = this.eventList.find(item => item.key === this.modelValue)
      console.log("eventItem", eventItem)
      this.$emit("selectionChanged", eventItem)
    }
  },
}
</script>
