<template>
  <ContentWrapper :links="topLinks">
    <Tabs type="card" v-model="position" :animated="false">
      <TabPane v-for="tab in PLAN_POSITIONS" :key="tab.key" :name="tab.key" :label="tab.name" :disabled="tab.disabled">
        <span style="color: #ccc">{{ tab.desc }}</span>
      </TabPane>
    </Tabs>
    <Form inline>
      <FormItem>
        <Button type="primary" @click="btnAddClicked">新建</Button>
      </FormItem>
      <FormItem>
        <Input v-model="queryForm.name" type="text" placeholder="名称过滤" />
      </FormItem>
      <FormItem>
        <DatePicker
          v-model="scheduleDateRange"
          type="datetimerange"
          placement="bottom-end"
          placeholder="计划上线时间范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
      <FormItem>
        <DatePicker
          v-model="createDateRange"
          type="datetimerange"
          placement="bottom-end"
          placeholder="创建时间范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
      <FormItem>
        <Select v-model="queryForm.status" placeholder="过滤状态">
          <Option v-for="(status, k) in PLAN_STATUS" :key="k" :value="k">
            {{ status.name }}
          </Option>
        </Select>
      </FormItem>
    </Form>
    <Table stripe :columns="tableColumns" :data="itemList" :loading="isLoading"></Table>
    <Pager :total="itemListCount" :current.sync="page" />
    <Modal v-model="dialogDisplay" :title="dialogTitle" @on-ok="onNewSubmit" width="90%">
      <PlanEditor v-if="dialogDisplay" ref="editor" :plan="currentPlan" :position="position" />
    </Modal>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"
import PlanEditor from "@/components/operation/PlanEditor.vue"
import { queryPlan, createPlan, updatePlan, deletePlan } from "@/api"
import { apiUtil, watchReload, editAndRemove } from "@/util"
import { PLAN_POSITIONS, HUMAN_PLAN_TYPE, getPositionByName, PLAN_STATUS } from "@/constants"
import "@/components/tableUtil"

export default {
  name: "PlanQueryPage",
  components: { ContentWrapper, Pager, PlanEditor },
  data() {
    return {
      PLAN_STATUS,
      PLAN_POSITIONS,
      topLinks: [{ title: "运营位", link: this.$route.path }],
      itemList: [],
      itemListCount: 0,
      isLoading: false,
      scheduleDateRange: ["", ""],
      createDateRange: ["", ""],
      page: 1,
      position: PLAN_POSITIONS[0].key,
      queryForm: { name: "" },
      dialogDisplay: false,
      dialogTitle: "",
      currentPlan: {},
      tableColumns: [
        { title: "标识", key: "id", width: 60, maxWidth: 60 },
        { title: "名称", key: "name" },
        {
          title: "类型",
          render: (h, p) => h("div", HUMAN_PLAN_TYPE(p.row.planType)),
          width: 100,
          maxWidth: 60,
        },
        {
          title: "内容",
          key: "planValue",
        },
        {
          title: "额外信息",
          key: "planExtra",
        },
        {
          title: "优先级",
          key: "priority",
          width: 50,
          maxWidth: 50,
        },
        {
          title: "状态",
          width: 80,
          maxWidth: 80,
          render: (h, p) =>
            h(
              "Tag",
              {
                props: {
                  color: p.row.status == "ONLINE" ? "green" : p.row.status == "PREPARE" ? "blue" : "default",
                },
              },
              PLAN_STATUS[p.row.status].name,
            ),
        },
        {
          title: "计划上线",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.scheduledAt, defText: "长期" },
            }),
        },
        {
          title: "计划下线",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.scheduledDownAt, defText: "长期" },
            }),
        },
        {
          title: "创建时间",
          render: (h, p) => h("DateHolder", { props: { time: p.row.createdAt } }),
        },
        {
          title: "操作",
          width: 150,
          render: (h, params) =>
            editAndRemove.call(
              this,
              h,
              params,
              () => {
                this.editPlan(params.row)
              },
              async () => {
                await this.deletePlan(params.row.id)
              },
            ),
        },
      ],
    }
  },
  computed: {
    positionIndex() {
      return PLAN_POSITIONS.reduce((acc, curr, idx) => {
        if (curr.name === this.position) {
          acc = idx
        }
        return acc
      }, 0)
    },
  },
  watch: {
    ...watchReload(["position", "queryForm", "scheduleDateRange", "createDateRange", "page"]),
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    btnAddClicked() {
      const t = {}
      t.id = null
      t.name = null
      t.planValue = null
      t.planExtra = null
      t.planType = getPositionByName(this.position).allowTypes[0]
      t.status = "PREPARE"
      t.relatedMediaSuffix = ""
      t.relatedMediaId = null
      t.relatedMediaPath = null
      t.relatedMediaUrl = null
      t.relatedCategoryId = null
      t.scheduledAt = null
      t.scheduledDownAt = null
      t.position = this.position
      this.currentPlan = Object.assign({}, t)
      this.dialogTitle = "添加"
      this.dialogDisplay = true
    },
    async reload() {
      apiUtil.call(this, async () => {
        let startScheduleTime = null,
          endScheduleTime = null
        if (this.scheduleDateRange && this.scheduleDateRange.length > 0 && this.scheduleDateRange[0] instanceof Date) {
          startScheduleTime = this.scheduleDateRange[0].getTime()
          endScheduleTime = this.scheduleDateRange[1].getTime()
        }
        let startTime = null,
          endTime = null
        if (this.scheduleDateRange && this.scheduleDateRange.length > 0 && this.scheduleDateRange[0] instanceof Date) {
          startScheduleTime = this.scheduleDateRange[0].getTime()
          endScheduleTime = this.scheduleDateRange[1].getTime()
        }

        const data = await queryPlan(
          this.page,
          this.position,
          this.queryForm.name,
          startScheduleTime,
          endScheduleTime,
          startTime,
          endTime,
          this.queryForm.status,
        )

        this.itemList = data.items
        this.itemListCount = data.count
      })
    },
    async onNewSubmit() {
      apiUtil.call(
        this,
        async () => {
          const editor = this.$refs.editor
          const formData = Object.assign({}, editor.formData)
          const position = getPositionByName(formData.position)
          formData.scheduledAt = formData.scheduledAt ? formData.scheduledAt.getTime() : null
          formData.scheduledDownAt = formData.scheduledDownAt ? formData.scheduledDownAt.getTime() : null
          if (formData.planType < 0) {
            throw new Error("类型错误")
          }
          if (position.allowTypes.indexOf(formData.planType) == -1) {
            throw new Error("此位置不允许此类型的推广")
          }
          if (!formData.name) {
            throw new Error("没有有效的名称")
          }
          if ((typeof formData.planValue === "string" && formData.planValue.trim() === "") || !formData.planValue) {
            throw new Error("运营内容不能为空")
          }
          if (!formData.position) {
            throw new Error("无效的位置")
          }
          if (!formData.priority || formData.priority < 0) {
            formData.priority = 0
          }
          if (formData.id) {
            await updatePlan(formData)
            this.$Message.success("编辑成功")
          } else {
            await createPlan(formData)
            this.$Message.success("创建成功")
          }
          await this.reload()
        },
        () => {
          this.dialogDisplay = true
        },
      )
    },
    editPlan(row) {
      const t = {}
      t.id = row.id
      t.name = row.name
      t.position = row.position
      t.planType = row.planType
      t.planValue = row.planValue
      t.planExtra = row.planExtra
      t.priority = row.priority
      t.relatedCategoryId = row.relatedCategoryId
      t.relatedMediaSuffix = row.relatedMediaSuffix
      t.relatedMediaId = row.relatedMediaId
      t.relatedMediaPath = row.relatedMediaPath
      t.relatedMediaUrl = row.relatedMediaUrl
      t.status = row.status != "CLOSED" ? "PREPARE" : "CLOSED"
      t.scheduledAt = row.scheduledAt ? new Date(row.scheduledAt) : null
      t.scheduledDownAt = row.scheduledDownAt ? new Date(row.scheduledDownAt) : null
      this.currentPlan = t
      this.dialogDisplay = true
      this.dialogTitle = "编辑"
    },
    async deletePlan(id) {
      apiUtil.call(this, async () => {
        await deletePlan(id)
        this.$Message.success("删除成功")
        await this.reload()
      })
    },
  },
}
</script>
