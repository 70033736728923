<template>
  <Form :labelWidth="180" :model="formData">
    <FormItem label="名称">
      <Input v-model="formData.name" placeholder="名称" :disabled="position === 'EVENT_PAGE'" />
    </FormItem>
    <FormItem label="位置">
      <Select v-model="formData.position" :disabled="true">
        <Option v-for="pos in PLAN_POSITIONS" :key="pos.key" :value="pos.key" :disabled="pos.disabled">
          {{ pos.name }}
        </Option>
      </Select>
    </FormItem>
    <FormItem label="类型">
      <Select v-model="formData.planType">
        <Option v-for="(planT, typeName) in availableTypes" :key="typeName" :value="typeName">
          {{ planT.name }}
        </Option>
      </Select>
    </FormItem>
    <FormItem :label="currentPlanValueContentLabel">
      <Input
        v-model="formData.planValue"
        placeholder=""
      />
      <Button
        v-if="PLAN_TYPES[safePlanType(position, formData.planType)].planValueContentType == 'ITEM'"
        @click="btnItemSelect"
      >
        选择商品
      </Button>
      <Button
        v-if="PLAN_TYPES[safePlanType(position, formData.planType)].planValueContentType == 'CATEGORY'"
        @click="btnCategorySelect"
      >
        选择分类
      </Button>
      <Button
        v-if="PLAN_TYPES[safePlanType(position, formData.planType)].planValueContentType == 'USER'"
        @click="btnUserSelect"
      >
        选择卖家
      </Button>
      <Button
        v-if="(PLAN_TYPES[formData.planType].planValueContentType === 'OFFLINE_ITEM')"
        @click="btnOfflineItemSelect('')"
      >
        选择线下商品
      </Button>
      <Button
        v-if="PLAN_TYPES[formData.planType].planValueContentType === 'SECKILL_ITEM'"
        @click="btnOfflineItemSelect('SECKILL')"
      >
        选择秒杀商品
      </Button>
      <Button
        v-if="PLAN_TYPES[formData.planType].planValueContentType === 'STORE'"
        @click="btnStoreSelect"
      >
        选择店铺
      </Button>
      <Button
        v-if="position === 'EVENT_PAGE'"
        @click="btnEventSelect"
      >
        {{ eventName ? eventName : '选择活动页' }}
      </Button>
    </FormItem>
    <FormItem
      label="商品名称"
      v-if="PLAN_TYPES[safePlanType(position, formData.planType)].planValueContentType == 'ITEM' || PLAN_TYPES[safePlanType(position, formData.planType)].planValueContentType == 'OFFLINE_ITEM' || PLAN_TYPES[safePlanType(position, formData.planType)].planValueContentType == 'SECKILL_ITEM'"
    >
      {{ formData.planExtra }}
    </FormItem>
    <FormItem
      label="类别名称"
      v-if="PLAN_TYPES[safePlanType(position, formData.planType)].planValueContentType == 'CATEGORY'"
    >
      {{ formData.planExtra }}
    </FormItem>
    <FormItem
      label="用户名称"
      v-if="PLAN_TYPES[safePlanType(position, formData.planType)].planValueContentType == 'USER'"
    >
      {{ formData.planExtra }}
    </FormItem>
    <FormItem
      label="店铺名称"
      v-if="PLAN_TYPES[safePlanType(position, formData.planType)].planValueContentType == 'STORE'"
    >
      {{ formData.planExtra }}
    </FormItem>
    <FormItem :label="planExtraName" v-if="planExtra">
      <Input v-model="formData.planExtra" placeholder="" />
    </FormItem>
    <template v-if="getPositionByName(position).allowImageUpload">
      <FormItem label="图片">
        <ImageUploader
          :defaultImages="currentImages"
          :defaultCrop="formData.relatedMediaSuffix"
          enableCrop
          :width="250"
          :height="250"
          @uploadSucceed="imageUploaded"
          @removedUploaded="imageRemoved"
        />
      </FormItem>
    </template>
    <FormItem label="优先级">
      <Input v-model="formData.priority" placeholder="数字越大展示越靠前" type="number" />
    </FormItem>
    <FormItem label="状态">
      <i-switch v-model="formData.status" true-value="PREPARE" false-value="CLOSED" />
    </FormItem>
    <FormItem label="上架时间">
      <DatePicker v-model="formData.scheduledAt" type="datetime" placeholder="计划上架时间" />
    </FormItem>
    <FormItem label="下架时间">
      <DatePicker v-model="formData.scheduledDownAt" type="datetime" placeholder="计划下架时间" />
    </FormItem>
    <Modal v-model="showItemDialog" fullscreen>
      <ItemSelector v-if="showItemDialog" @itemSelected="itemSelected" />
      <div slot="footer"></div>
    </Modal>
    <Modal v-model="showCategoryDialog" width="400px">
      <CategorySelector v-if="showCategoryDialog" @categorySelected="categorySelected" />
      <div slot="footer"></div>
    </Modal>
    <Modal v-model="showUserSelectorDialog" width="80%">
      <UserSelector v-if="showUserSelectorDialog" :max="1" @usersChanged="userSelected" />
      <div slot="footer">
        <Button @click="showUserSelectorDialog = false">确定</Button>
      </div>
    </Modal>
    <Modal v-model="showOfflineItemSelectorDialog" width="80%">
      <ItemSelector v-if="showOfflineItemSelectorDialog" :variant="'STORE_FOOD'" :initRecommendType="recommendType" :max="1" @itemSelected="offflineItemSelected" />
    </Modal>
    <Modal v-model="showStoreSelectorDialog" width="80%">
      <StoreList v-if="showStoreSelectorDialog" @selectionChanged="storeSelected" />
    </Modal>
    <Modal v-model="showEventListDialog" width="400px">
      <EventList v-if="showEventListDialog" @selectionChanged="eventSelected" />
      <div slot="footer">
      </div>
    </Modal>
  </Form>
</template>

<script>
import ItemSelector from "@/components/item/ItemSelector"
import CategorySelector from "@/components/cate/CategorySelector"
import UserSelector from "@/components/user/UserSelector"
import ImageUploader from "@/components/ImageUploader"
import StoreList from "@/components/store/StoreList"
import EventList from "@/components/operation/EventList"
import { PLAN_TYPES, PLAN_POSITIONS, getPositionByName } from "@/constants"
const initData = () => {
  return {
    name: "",
    position: "",
    planType: "TEXT",
    planValue: "",
    priority: null,
    status: "PREPARE",
    scheduledAt: null,
    scheduledDownAt: null,
    relatedMediaId: null,
    relatedMediaPath: null,
    relatedMediaSuffix: "",
    relatedMediaUrl: null,
    recommendType: ""
  }
}
const safePlanType = (position, currentPlanType) => {
  return currentPlanType == undefined ||
    currentPlanType == null ||
    !currentPlanType ||
    getPositionByName(position).allowTypes.indexOf(currentPlanType) == -1
    ? getPositionByName(position).allowTypes[0]
    : currentPlanType
}

// const maxFiles = 1

export default {
  name: "PlanEditor",
  components: { ItemSelector, CategorySelector, ImageUploader, UserSelector, StoreList, EventList },
  props: {
    plan: {
      type: Object,
      default: () => Object.assign({}, initData()),
    },
    position: {
      type: String,
      default: PLAN_POSITIONS[0].key,
    },
  },
  data() {
    return {
      PLAN_TYPES,
      PLAN_POSITIONS,
      getPositionByName,
      safePlanType,
      formData: Object.assign({}, this.plan, {
        position: this.position,
      }),
      showItemDialog: false,
      showCategoryDialog: false,
      showUserSelectorDialog: false,
      showOfflineItemSelectorDialog: false,
      showStoreSelectorDialog: false,
      showEventListDialog: false,
      token: sessionStorage.getItem("token"),
      imageSizes: [],
      // 活动页名称
      eventName: null
    }
  },
  computed: {
    currentImages() {
      if (this.formData.relatedMediaPath && this.formData.relatedMediaPath != "") {
        return [this.formData.relatedMediaPath]
      }
      return []
    },
    currentPlanValueContentLabel() {
      if (this.formData.planType != null) return PLAN_TYPES[this.formData.planType].planValueContentLabel
      else return "请选择类型"
    },
    planExtra() {
      if (this.formData.planType != null) return PLAN_TYPES[this.formData.planType].extra !== false
      else return false
    },
    planExtraName() {
      if (this.formData.planType != null) return PLAN_TYPES[this.formData.planType].extraName
      else return "请选择类型"
    },
    availableTypes() {
      const types = PLAN_POSITIONS.reduce((types, currentPosition) => {
        if (currentPosition.key == this.formData.position) {
          currentPosition.allowTypes.forEach(key => (types[key] = PLAN_TYPES[key]))
        }
        return types
      }, {})
      return types
    },
  },
  watch: {
    position(newV) {
      this.formData.position = newV
      this.formData.planType = safePlanType(newV, this.formData.planType)
      this.formData.planValue = ""
      this.formData.planExtra = ""
    },
    plan(newV) {
      this.formData = Object.assign({}, newV)
    },
    formData: {
      deep: true,
      handler(newV) {
        if (newV.planType == "SCANNER") {
          this.formData.planValue = "com.skykiwi.dingdang://scanner?"
        }
        this.formData.planType = safePlanType(this.position, newV.planType)
      },
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {},
    doReset() {},
    async doUpdate() {},
    btnItemSelect() {
      this.showItemDialog = true
    },
    btnCategorySelect() {
      this.showCategoryDialog = true
    },
    btnUserSelect() {
      this.showUserSelectorDialog = true
    },
    btnOfflineItemSelect(type) {
      this.recommendType = type
      this.showOfflineItemSelectorDialog = true
    },
    btnStoreSelect() {
      this.showStoreSelectorDialog = true
    },
    btnEventSelect() {
      this.showEventListDialog = true
    },
    itemSelected(item) {
      this.showItemDialog = false
      this.formData.planValue = item.id
      this.formData.planExtra = item.title
      if (!this.formData.name || this.formData.name.trim() === "") {
        this.formData.name = item.title
      }
    },
    offflineItemSelected(item) {
      this.showOfflineItemSelectorDialog = false
      this.formData.planValue = item.id
      this.formData.planExtra = item.title
      if (!this.formData.name || this.formData.name.trim() === "") {
        this.formData.name = item.title
      }
    },
    storeSelected(store) {
      this.showStoreSelectorDialog = false
      this.formData.planValue = store.storeAlias
      this.formData.planExtra = store.name
      if (!this.formData.name || this.formData.name.trim() === "") {
        this.formData.name = store.name
      }
    },
    categorySelected(category) {
      this.showCategoryDialog = false
      this.formData.planValue = category.id
      this.formData.planExtra = category.name
      if (!this.formData.name || this.formData.name.trim() === "") {
        this.formData.name = category.name
      }
    },
    eventSelected(event) {
      this.showEventListDialog = false
      this.formData.name = event.key
      this.eventName = event.name
    },
    userSelected(users) {
      console.log(users)
      // this.showUserSelectorDialog = false
      if (users && users.length > 0) {
        this.formData.planValue = users[0].id
        this.formData.planExtra = users[0].name
        if (!this.formData.name || this.formData.name.trim() === "") {
          this.formData.name = users[0].name
        }
      }
    },
    imageUploaded(uploadedImages) {
      if (uploadedImages && uploadedImages.length > 0) {
        this.formData.relatedMediaPath = uploadedImages[0].path
        this.formData.relatedMediaSuffix = uploadedImages[0].suffix
      } else {
        this.formData.relatedMediaId = null
        this.formData.relatedMediaPath = ""
        this.formData.relatedMediaSuffix = ""
      }
    },
    imageRemoved() {
      this.formData.relatedMediaId = null
      this.formData.relatedMediaPath = ""
      this.formData.relatedMediaSuffix = ""
    }
  },
}
</script>

<style></style>
